.contentSubHeader {
  margin-bottom: var(--content-sub-header-spacing);

  &.topMargin {
    margin-top: 10px;
  }
}

.containerItem {
  padding: var(--container-item-y-spacing) 0;
  padding-left: var(--content-side-padding-px-small);
  padding-right: var(--content-side-padding-px-small);
  margin-left: var(--content-side-margin-px-small);
  margin-right: var(--content-side-margin-px-small);

  @media (--min-width-small) {
    padding-left: var(--content-side-left-padding-px-med);
    padding-right: var(--content-side-padding-right-scrollbar-px-med);
    margin-left: var(--content-side-left-margin-px-med);
    margin-right: var(--content-side-margin-right-scrollbar-px-med);
  }

  @media (--min-width-xlarge) {
    padding-right: var(--content-side-right-padding-px-med);
    margin-right: var(--content-side-right-margin-px-med);
  }

  :global(html.ford) & {
    padding-left: var(--content-side-padding-px-small);
    padding-right: var(--content-side-padding-px-small);
    margin-left: 0;
    margin-right: 0;
  }

  &:first-child {
    @media (--min-width-xxsmall) {
      margin-top: calc(-1 * var(--container-item-y-spacing));
    }
  }

  &.profilePage {
    @media (--min-width-medium) and (--max-width-xlarge) {
      margin-right: 8px;
    }
  }

  &.noSpace {
    padding: 0;
    margin: 0;
  }
}

.matrixContainer {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 16px), 1fr));

  @media (--min-width-small) {
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 16px), 1fr));
  }

  &.squareTileLayout {
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 24px), 1fr));

    @media (--min-width-small) {
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 24px), 1fr));
    }

    @media (--min-width-medium) {
      grid-template-columns: repeat(auto-fill, minmax(calc(16.66% - 24px), 1fr));
    }
  }

  &.fixedSquareTileLayout {
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 16px), 1fr));
  }
}

.adContainer {
  padding: 0;
}

.reducedMargin {
  margin-bottom: 20px;
}

.noTopPadding:not(:first-child) {
  padding-top: 0;
}

.containerDivider {
  width: 100%;
  margin-top: 40px;
}
