.container {
  margin-top: calc(var(--default-margin-px) * 2);
}

.prompt {
  padding: 0;
  text-align: center;
  background-color: var(--grey-poupon);
  word-wrap: break-word;
  color: rgb(0 0 0 / 87%);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 12px;

  &.isDiscord {
    background-color: var(--medium-grey);
  }
}

.text {
  color: var(--secondary-color-5);
  line-height: 24px;
  padding: 45px 52px;

  &.isDiscord {
    color: var(--grey-6);
  }
}

.thumb {
  height: 40px;
  width: 40px;
  color: var(--secondary-color-5);
}
