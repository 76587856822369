/* CSS for schedule tile */
.dateWrapper {
  text-align: center;
  padding-top: 7px;
  border-radius: 3px;
  background-color: var(--secondary-color-5);

  [data-darkmode='true'] & {
    background-color: var(--white);
  }

  :global(html.discord) & {
    background-color: var(--medium-grey);
    border-radius: 9px;
  }

  :global(html.ford) & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 200%;
  }

  @media (--min-width-small) {
    padding-top: 45px;
  }

  @media (--min-width-medium) {
    padding-top: 7px;

    :global(html.discord) & {
      padding-top: 5px;
    }
  }

  @media (--min-width-xxlarge) {
    padding-top: 20px;

    :global(html.discord) & {
      padding-top: 10px;
    }
  }
}

.month {
  color: var(--t-sharp);
  text-transform: uppercase;
  font-weight: 700;

  :global(html.discord) & {
    color: var(--polo-blue);
    font-weight: var(--font-weight-bold);
  }

  @media (--min-width-xxlarge) {
    :global(html.discord) & {
      font-size: var(--font-size-5);
    }
  }
}

.date {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  color: #fff;

  [data-darkmode='true'] & {
    color: var(--secondary-color-5);
  }

  :global(html.discord) & {
    color: var(--anti-flash-white);
    font-weight: 400;
  }

  @media (--min-width-xxlarge) {
    :global(html.discord) & {
      font-size: 36px;
    }
  }
}
