.titleText {
  font-size: var(--font-size-2);
  line-height: 19px;
}

.subTitleText {
  font-family: var(--mono-font);
  color: var(--cloud-grey);
  font-size: var(--font-size-1);
  line-height: 16px;
}
