$arrowBorderSize: 12px;
$popoverWidth: 210px;

.playSectionContainer {
  cursor: pointer;
}

.playSectionLeftContent {
  float: left;
  width: 50px;
  height: 100%;
}

.playSectionMainContent {
  margin-left: 50px;
}

.playSectionButtonWrapper {
  position: absolute;
  left: -7px;
  top: -9px;
}

.popover {
  left: 20%;
}
