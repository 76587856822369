.actionButton {
  width: fit-content;
  cursor: pointer;
  position: relative;
  text-align: center;
  margin-top: 16px;
  display: inline-block;
  margin-right: 10px;

  @media (--min-width-breakpoint-360) {
    margin-right: 16px;
  }

  @media (--min-width-xsmall) {
    margin-right: 32px;
  }

  @media (--min-width-xlarge) {
    margin-right: 16px;
  }

  @media (--min-width-xxlarge) {
    margin-right: 32px;
  }

  .buttonText {
    font-weight: bold;
    font-size: var(--font-size-1);
    line-height: 16px;
    letter-spacing: var(--letter-spacing-loose);
    margin: 15px;
    text-transform: uppercase;

    &.underlineText {
      border-bottom: 2px solid var(--secondary-color-5);
      padding-bottom: 2px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.disabled {
    border: none;
    background-color: var(--51st-shade-of-grey);
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.roundedButton {
  border-radius: 42px;
  min-width: 160px;

  @media (--min-width-breakpoint-360) {
    min-width: 175px;
  }

  @media (--min-width-small) {
    min-width: 216px;
  }

  @media (--min-width-xlarge) {
    min-width: 160px;
  }

  @media (--min-width-xxlarge) {
    min-width: 216px;
  }
}
