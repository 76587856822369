.container {
  position: relative;
  background-color: var(--content-area-background-color-hex);
  padding-top: 30px;
  padding-bottom: 24px;
  margin: var(--container-item-y-spacing) 0;
  margin-left: calc(-1 * var(--content-side-padding-px-small));
  width: calc(
    100% + var(--content-side-padding-px-small) +
      var(--content-side-padding-px-small)
  );
  overflow: hidden;

  @media (--min-width-small) {
    margin-left: calc(
      -1 * ((
              var(--content-side-left-padding-px-med) +
                var(--content-side-right-padding-px-med)
            ) / 2)
    );
    width: calc(
      100% + var(--content-side-left-padding-px-med) +
        var(--content-side-padding-right-scrollbar-px-med)
    );
  }

  @media (--min-width-xlarge) {
    width: calc(
      100% + var(--content-side-left-padding-px-med) +
        var(--content-side-right-padding-px-med)
    );
  }

  @media (--min-width-xxxlarge) {
    margin-left: 0;
    width: auto;
  }

  [data-darkmode='true'] & {
    background-color: var(--ink-med);
  }
}

.containerTitle {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: var(--letter-spacing-loose);
  text-align: center;
  color: var(--cloud-grey);
}

.ad {
  background-color: var(--grey-2);
  margin: auto;
  box-shadow: 0 3px 4px 1px rgb(28 31 58 / 12%);

  [data-darkmode='true'] & {
    background-color: #606376;
  }
}
