$minimizeButtonWidth: 22px;
$minimizeContainerHeight: 30px;
$heartIconWidth: 30px;
$topSectionButtonMargin: 10px;
$actionsAndTitlePaddingTop: 20px;
$titleWidth: calc(
  100% - #{$minimizeButtonWidth} - #{$heartIconWidth} - #{$topSectionButtonMargin} *
    2
);

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate(-100%);
  }

  51% {
    opacity: 0;
    transform: translate(100%);
  }

  52% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

.innerDesktopContent {
  text-align: center;
  font-weight: var(--font-weight-bold);
  padding-top: 20px;
  padding-bottom: 20px;

  @media (--min-width-xxxlarge) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .flexContainer {
    display: flex;
    flex-direction: column;

    .artworkImageContainer {
      height: 34vw;
      width: 34vw;
      align-self: center;

      .artworkImage {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
      }
    }

    .underImageTextContainer {
      width: 34vw;
      align-self: center;

      .subtitle {
        color: white;
        font: var(--primary-font-semi-bold);
        font-size: 22px;
        font-weight: lighter;
        text-align: left;
        margin: 10px 0;
      }

      .title {
        color: white;
        margin: 15px 0 0;
        text-align: left;
        line-height: 1.25;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-6);
        font-weight: bolder;
      }
    }
  }
}

.artworkImageContainer {
  position: relative;
  height: 44vw;
  aspect-ratio: 1 / 1;
  margin-inline: auto;
  border-radius: 12px;
  overflow: hidden;

  :global(html.discord) & {
    height: 33vh;

    @media (--min-width-discord-picture-in-picture-medium) {
      height: 50vh;
    }

    @media (--min-width-xxsmall) {
      height: 32vh;
    }
  }

  @media (--min-width-breakpoint-360) {
    height: 60vw;
  }

  &.smallerImage {
    height: 40vw;

    @media (--min-width-breakpoint-360) {
      height: 52vw;
    }
  }

  .artworkImage {
    height: 100%;
  }
}

.controlsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  &.newControls {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @media (--min-width-discord-picture-in-picture-medium) {
      display: flex;
    }
  }

  @media (--min-width-breakpoint-360) {
    margin-top: 20px;
  }

  :global(html.discord.mobile) & {
    margin-top: 92px;
  }
}

.playButtonContainer {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 80px;
  height: 80px;

  &.withSpeedControl {
    margin-left: 30px;
    margin-right: 70px;

    @media (--min-width-xxsmall) {
      margin-left: 40px;
      margin-right: 80px;
    }
  }

  :global(html.discord) & {
    path {
      fill: var(--grey-7);
    }
  }
}

.speedControlContainer {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--ink-light);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding-top: 12.5px;
}

.subtitle {
  position: relative;
  display: block;
  color: white;
  font: var(--primary-font-semi-bold);
  font-size: var(--font-size-5);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 15px 15px 10px;
  width: calc(100% - 30px);

  @media (--min-width-breakpoint-360) {
    margin: 25px 25px 20px;
    width: calc(100% - 50px);
    font-size: 22px;
  }

  :global(html.discord) & {
    margin: 10px 0 4px;
    width: 100%;
    font-size: var(--font-size-3);

    @media (--min-width-xxsmall) {
      font-size: var(--font-size-4);
      font-weight: 600;
    }
  }
}

.subtitleProfileLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    text-decoration: none;
  }

  & > * {
    color: var(--text-color);
    opacity: 0.6;
  }

  span {
    font-family: var(--mono-font);
    font-size: 10px;
    text-decoration: none;

    @media (--min-width-xxsmall) {
      font-size: var(--font-size-3);
    }

    :global(html.discord) & {
      font-size: 10px;
    }
  }
}

.scrubberContainer {
  display: none;
  color: white;
  max-width: 100%;
  margin-top: 10px;

  @media (--min-width-discord-picture-in-picture-medium) {
    display: block;
  }

  @media (--min-width-breakpoint-360) {
    margin-top: 20px;
  }

  .scrubber {
    max-width: 100%;
  }
}

.controlPillContainer {
  text-align: center;

  .controlPill {
    display: none;
    padding: 2px 16px 4px;
    margin-top: 24px;
    background-color: var(--medium-grey);
    border-radius: 24px;

    @media (--min-width-discord-picture-in-picture-medium) {
      display: inline-block;
    }

    &.speedControlPill {
      padding: 4px 12px;
      background-color: transparent;
      border: 1px solid var(--medium-grey);

      .speedControlIcon > div {
        font-size: var(--font-size-1);
        font-weight: bold;
      }
    }

    span {
      font-weight: bold;
      font-size: 11px;
      color: var(--text-color);
    }
  }
}

.scrolling {
  width: fit-content;
  animation: scroll 12s infinite linear;
}

.dialogStyle {
  padding: unset;
}

.topSection {
  display: none;
  flex-direction: column;
  width: 100%;

  @media (--min-width-xxsmall) {
    display: flex;
  }

  .actionsAndTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding-top: 15px;
    min-height: 30px;

    @media (--min-width-breakpoint-360) {
      padding-top: $actionsAndTitlePaddingTop;
    }

    :global(html.discord.mobile) & {
      padding-top: var(--discord-browsies-header-top-padding);
    }

    .title {
      color: white;
      font-size: var(--font-size-3);
      line-height: 1.25;
      font-weight: var(--font-weight-bold);
      overflow: hidden;
      white-space: nowrap;
    }

    .minimizeContainer {
      display: flex;
      align-items: center;
      height: $minimizeContainerHeight;
      flex-grow: 1;
      flex-basis: 0;

      &.navBackButton {
        position: absolute;
        left: 20px;
      }

      .minimizeButton {
        width: $minimizeButtonWidth;
        height: 16px;
        fill: white;
        margin-top: 2px;
      }
    }

    .actionsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: $topSectionButtonMargin;
      flex-grow: 1;
      flex-basis: 0;
    }

    .actionDrawerIconContainer {
      display: flex;
      background-color: transparent;

      path {
        stroke: var(--anti-flash-white);
      }
    }

    &.spaceBelowTitle {
      margin-bottom: 20px;
    }

    &.shouldExpandSection {
      margin-bottom: calc(
        20vh - $actionsAndTitlePaddingTop - $minimizeContainerHeight
      );
    }
  }
}

.bottomSection {
  :global(html.discord) & {
    height: 20vh;
    display: flex;
    justify-content: center;

    .liveBug {
      border-radius: 20px;
      background: rgb(241 242 243 / 20%);
      height: 30px;
      transform: translate(0%, 100%);
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        color: var(--anti-flash-white);
        letter-spacing: var(--letter-spacing-loose);
        font-size: 11px;
        font-weight: 700;
      }
    }
  }
}

.gradient {
  height: 100%;
  width: 10px;
  z-index: var(--top-z-index);
  top: 0;
  position: absolute;

  :global(html.discord) & {
    display: none;
  }
}

.leftGradient {
  composes: gradient;
  background: linear-gradient(
    to right,
    var(--secondary-color-5),
    rgb(28 32 60 / 25%)
  );
  left: 0;
}

.rightGradient {
  composes: gradient;
  background: linear-gradient(
    to left,
    var(--secondary-color-5),
    rgb(28 32 60 / 25%)
  );
  right: 0;
}

.upsellRibbon {
  margin-left: -25px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100vw;
}
