@keyframes hidePrompt {
  0% {
    opacity: 1;
  }

  30% {
    padding-top: 0;
    padding-bottom: 0;
  }

  75% {
    max-height: 0;
    opacity: 0;
    margin-top: 0;
  }

  100% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: calc(-2 * var(--container-item-y-spacing));
  }
}

.promptContainer {
  display: flex;
  position: relative;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  @media (--min-width-medium) {
    padding: 24px;
  }

  &.withCloseIcon {
    padding: 16px 36px 16px 16px;

    @media (--min-width-medium) {
      padding: 24px 36px 24px 24px;
    }
  }

  &.noMargins {
    border-radius: unset;
    width: 100%;

    @media (--min-width-small) {
      width: calc(100% + var(--content-side-padding-px-small));
    }

    @media (--min-width-xlarge) {
      width: calc(
        100% + var(--content-side-padding-px-small) +
          var(--content-side-padding-px-small)
      );
    }
  }

  .image {
    display: inline-block;
    width: 64px;
    min-width: 64px;
    margin: auto;
    flex: none;

    &.imageLarge {
      width: 156px;
      max-width: 40%;

      @media (--min-width-small) {
        max-width: unset;
      }
    }

    &.imageXXLarge {
      width: 42%;
    }

    @media (--min-width-xsmall-1) {
      width: 96px;

      &.imageSmall {
        width: 80px;
      }

      &.imageLarge {
        width: 156px;
      }
    }
  }

  .imageLeft {
    order: -1;
    margin: auto 16px auto 0;

    @media (--min-width-medium) {
      margin-right: 24px;
    }
  }

  .imageRight {
    margin: auto 0 auto auto;
  }

  .textAndButtonsContainer {
    display: inline-block;
    max-width: 655px;

    &.onLeftSide {
      margin-right: 16px;
    }

    &.textWidthMedium {
      max-width: 60%;

      &.withoutImage {
        max-width: 100%;
      }

      @media (--min-width-small) {
        max-width: 70%;

        &.withoutImage {
          max-width: 70%;
        }
      }

      @media (--min-width-large) {
        max-width: 60%;

        &.withoutImage {
          max-width: 60%;
        }
      }

      @media (--min-width-xlarge) {
        max-width: 65%;

        &.withoutImage {
          max-width: 65%;
        }
      }
    }

    .titleContainerTopMarginXLarge {
      margin-top: 32px;

      @media (--min-width-breakpoint-360) {
        margin-top: 24px;
      }

      @media (--min-width-medium) {
        margin-top: 48px;
      }

      @media (--min-width-large) {
        margin-top: 72px;
      }
    }

    .titleContainerBottomMarginLarge {
      margin-bottom: 16px;
    }

    .title {
      max-width: 228px;
      font-weight: bold;
      font-size: 21px;
      line-height: 1;
      height: 100%;
      margin-bottom: 10px;

      @media (--min-width-breakpoint-360) {
        max-width: 264px;
      }

      @media (--min-width-xsmall) {
        max-width: 316px;
      }

      @media (--min-width-small) {
        max-width: 408px;
      }

      &.withoutImage {
        max-width: unset;
      }

      &.multiLineTitle {
        height: auto;
        line-height: 5vw !important;

        & span {
          padding: 4px 4px 0;
        }
      }

      &.titleSizeLarge {
        font-size: 6vw;

        @media (--min-width-small) {
          font-size: 31px;
          line-height: 31px !important;
        }
      }
    }

    .subtitle {
      word-wrap: normal;
      text-align: left;
      font-size: var(--font-size-2);
      line-height: 19px;
    }
  }

  .closeIconClassName {
    position: absolute;
    top: 18px;
    right: 18px;
  }

  &.hidden {
    animation: hidePrompt 400ms linear forwards;
  }
}
