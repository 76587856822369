.itemCardContainer {
  background-color: var(--secondary-color-5);
  color: var(--white);

  :global(html.discord) & {
    background-color: var(--medium-grey);
    color: var(--text-color);
    padding: 16px;
    border-radius: 15px;
  }
}

.topContainer {
  margin-bottom: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-height: 52px;

  .image {
    width: 52px;
    height: 52px;
    margin-right: 12px;
    border-radius: 4px;
  }

  .title {
    margin-right: 0;

    :global(html.discord) & {
      color: var(--text-color);
    }

    & div {
      font-weight: var(--font-weight-bold);
      line-height: 1.22;
      font-size: var(--font-size-4);
    }
  }

  .subtitle {
    margin-right: 0;

    & div {
      line-height: 1.19;
      font-size: var(--font-size-3);
    }
  }
}

.description {
  font-size: var(--font-size-3);
  line-height: 1.25;
  margin-bottom: 14px;
}

.profileLink {
  text-transform: uppercase;
  color: var(--white);
  line-height: 1.13;
  font-weight: var(--font-weight-bold);

  :global(html.discord) & {
    color: var(--text-color);
  }
}
