.playProgressContainer {
  height: 35px;
  width: 35px;
  margin: 10px 13px;
}

.playButton {
  transition: var(--base-transition);
  height: 35px;
  width: 35px;

  &:hover {
    opacity: 0.8;
  }

  &:not(.discordPlayableSection) {
    :global(html.discord) div[data-icon='play'] & {
      circle {
        fill: var(--anti-flash-white) !important;
      }

      path:nth-child(2) {
        fill: var(--dark-grey) !important;
      }
    }

    :global(html.discord) div[data-icon='play'][data-status='complete'] & {
      path:nth-child(2) {
        fill: var(--anti-flash-white) !important;
      }
    }

    :global(html.discord) &[data-icon='pause'] {
      path:nth-child(2) {
        fill: var(--anti-flash-white) !important;
      }

      rect {
        fill: var(--dark-grey) !important;
      }
    }
  }
}

.popOutButton {
  margin-top: 5px;
  margin-left: 12px;
}
