.adOverlay {
  z-index: var(--now-playing-ad-z-index);
  position: absolute;
  width: 300px;
  transform: translateX(-50%);
  left: 50%;
}

.hiddenAdOverlay {
  /* cannot use display: none here because we are checking visibility of the ad in mint */
  position: absolute;
  visibility: hidden;
}

.adInnerContainer {
  position: relative;
  max-width: 300px;
  margin: auto;

  .adCloseIconContainer {
    opacity: 1;
    float: right;

    .adCloseIcon {
      display: block;
      background: transparent url('/assets/img/ads/close-icon.svg') no-repeat;
      background-size: contain;
      width: 27px;
      height: 27px;
      cursor: pointer;
    }
  }

  .ad {
    width: 300px;
    height: 250px;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--black);
  }
}
