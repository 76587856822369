.premiumLogoWrapper {
  padding: 2px 10px;
  background-color: var(--tunein-coral);
  border-radius: 18px;
  display: flex;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  transition: var(--base-transition);

  &:hover {
    opacity: 0.8;
  }
}

.premiumLogoText {
  color: var(--content-area-background-color-hex);
  letter-spacing: var(--letter-spacing-loose);
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  padding-right: 3px;
  text-transform: uppercase;
}

.premiumLogo {
  display: flex;
}
