.collapsibleLabel {
  display: flex;
  align-items: center;

  [data-darkmode='true'] & {
    /* convert caret svg colors to white, also converts text color to teal */
    filter: invert(67%) sepia(22%) saturate(1219%) hue-rotate(125deg)
      brightness(88%) contrast(80%);
  }

  &.isDiscord {
    filter: unset;
  }
}

.caret {
  width: 14px;
  height: 14px;
}
