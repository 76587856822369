.containerTitleContainer {
  display: flex;

  &.spaceBetween {
    justify-content: space-between;
  }
}

.browseLink {
  display: flex;
  align-items: center;
}

.titleContent {
  display: flex;
  align-items: center;
}

.titleContainer {
  max-width: 83.33%;
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;

  @media (--min-width-small) {
    max-width: 66.67%;
  }

  @media (--min-width-medium) {
    max-width: 50%;
  }

  @media (--min-width-xlarge) {
    max-width: 66.67%;
  }

  @media (--min-width-xxlarge) {
    max-width: 50%;
  }

  :global(html.ford) & {
    max-width: 100%;
  }
}

.titleHeader {
  margin-right: 1px;
  font-size: var(--font-size-3);
  color: var(--text-color);
  font-weight: bold;
  line-height: 21px;

  [data-darkmode='true'] & {
    color: var(--anti-flash-white);
  }

  :global(html.ford) & {
    font-size: var(--font-size-4);
  }

  &.titleHeaderLarge {
    font-size: var(--font-size-7);

    @media (--min-width-small) {
      font-size: 36px;
    }
  }

  &.titleHeaderMini {
    font-size: var(--font-size-3);
  }
}

.subtitleHeader {
  margin-top: 4px;
  margin-right: 1px;
  font-size: var(--font-size-1);
  color: var(--text-color);

  [data-darkmode='true'] & {
    color: var(--white);
  }

  :global(html.discord) & {
    color: var(--white);
    opacity: 0.5;
  }
}

.displayChevron {
  @media (--min-width-small) {
    display: none !important;
  }

  .chevron {
    width: 15px;
    height: 15px;
    transform: translateY(2px);
  }
}

.seeAll {
  display: none;
  line-height: 16px;
  font-weight: bold;
  font-size: var(--font-size-1);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-loose);
  transform: translateY(2px);

  @media (--min-width-small) {
    display: inline;
  }
}

.premiumLogo {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  padding-left: 20px;
}

.srOnly {
  composes: screenReaderOnly from '../../../styles/common.module.scss';
  top: 10px;
}
