.episodeCardContainer {
  background-color: var(--ink-med);
  padding: 19px;
  border-radius: 6px;
  color: var(--white);

  .title {
    font-weight: var(--font-weight-bold);
    margin-bottom: 12px;
    font-size: 13px;
    line-height: 1.15;
  }

  .subtitle {
    font-weight: var(--font-weight-bold);
    margin-bottom: 12px;
    font-size: var(--font-size-4);
    line-height: 1.22;
  }

  .description {
    width: 100%;
    margin-bottom: 10px;
    font-size: var(--font-size-3);
    line-height: 1.25;
  }

  .dateAndDurationContainer {
    margin-bottom: 7px;
    font-size: 13px;
    line-height: 1.54;

    & > div {
      font-weight: var(--font-weight-bold);
    }
  }
}
