.upsellRibbon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background-color: rgb(255 255 255 / 5%);

  @media (--min-width-breakpoint-360) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.overlayText {
  align-self: center;
  display: inline-block;
  color: white;
  font-size: var(--font-size-3);
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  margin-left: 16px;
  align-self: center;
  display: inline-block;
  border-radius: 12px;
  width: fit-content;
  height: 24px;

  span {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-2);
    margin: -1px 10px 0;
    white-space: nowrap;
  }
}
